import {
  ChevronDownIcon,
  ChevronLeftIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MerchantCount } from '../../pages/Dashboard';
import DynamicTable, { Column } from '../common/DynamicTable';
import MetricDisplay, { Metric } from './MetricDisplay';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateTime, Duration } from 'luxon';
import useRequest, { RequestParams } from '../../hooks/useRequest';
import { Merchant } from '../common/types';
import { apiPaths } from '../../utils/ApiPaths';
import Shimmer from '../common/Shimmer';
import BaseContext from '../common/BaseContext';
import CheckboxFilter from './Filter/CheckboxFilter';
import FilterPanel from './FilterPanel';
import Fuse from 'fuse.js';
import {
  Listbox,
  ListboxButton,
  Transition,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DateRange,
  DateRangeShortcut,
  DateRangeShortcuts,
  formatDateRange,
} from '../../utils/DateRange';

const METRIC_RANGE = [
  {
    inputValue: 1,
    label: 'Today',
    units: 'Merchants',
  },
  {
    inputValue: 7,
    label: 'Last 7 Days',
    units: 'Merchants',
  },
  {
    inputValue: 15,
    label: 'Last 15 Days',
    units: 'Merchants',
  },
  {
    inputValue: 30,
    label: 'Last 30 Days',
    units: 'Merchants',
  },
];

enum MerchantStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

//Helper functions
const formatDate = (date: string): string => {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd MMM yyyy');
};

const getStatusStyles = (status: string) => {
  switch (status) {
    case MerchantStatus.ACTIVE:
      return 'bg-green-200 text-green-700';
    case MerchantStatus.INACTIVE:
      return 'bg-red-200 text-red-700';
  }
};

/**
 * Calculates the duration between two dates and formats it into a human-readable string.
 *
 * @param startDate - The start date as a string in ISO 8601 format.
 * @param endDate - The end date as a string in ISO 8601 format.
 * @returns A string representing the duration in a human-readable format.
 */
function formatDuration(startDate: Date, endDate: Date): string {
  // Parse the start and end dates using Luxon
  const start: DateTime = DateTime.fromJSDate(startDate);
  const end: DateTime = DateTime.fromJSDate(endDate);

  // Calculate the duration between the start and end dates
  const duration: Duration = end.diff(start, ['years', 'months', 'days']);

  // Extract the duration components and floor them to whole numbers
  const years: number = Math.floor(duration.years);
  const months: number = Math.floor(duration.months);
  const days: number = Math.floor(duration.days);

  // Create an array to store the formatted duration components
  const formatParts: string[] = [];

  // Add the formatted duration components to the array
  if (years > 0) {
    formatParts.push(`${years} year${years > 1 ? 's' : ''}`);
  }
  if (months > 0) {
    formatParts.push(`${months} month${months > 1 ? 's' : ''}`);
  }
  if (days > 0) {
    formatParts.push(`${days} day${days > 1 ? 's' : ''}`);
  }

  // Join the formatted duration components into a single string
  const formattedDuration: string = formatParts.join(', ');

  return formattedDuration;
}

export const Merchants: React.FC = () => {
  // Hooks
  const { organization, setLoading, setOrganization } = useContext(BaseContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // API requests
  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { executeRequest: fetchMerchantsData, data: merchants } = useRequest<Merchant[]>(
    apiPaths.GET_MERCHANTS,
    [],
  );

  const [filteredMerchants, setFilteredMerchants] = useState([]);

  const {
    data: merchantCount,
    executeRequest: fetchMerchantCount,
    setData: setMerchantCount,
  } = useRequest<MerchantCount>(`/api/v1/merchants/count`);

  // State
  const [metrics, setMetrics] = useState<Metric[]>(
    METRIC_RANGE.map((range) => ({ ...range, value: 0 })),
  );
  const [isMetricsLoading, setIsMetricsLoading] = useState<boolean>(true);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<{ value: string; label: string }[]>([]);
  const [filter, setFilters] = useState<{
    status: string[];
  }>();
  const [isCustomDateRangePickerOpen, setIsCustomDateRangePickOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [interval, setInterval] = useState<DateRangeShortcut>(() => {
    const savedInterval = DateRangeShortcuts.find(
      (item) => item.code === searchParams.get('interval'),
    );
    if (savedInterval) return savedInterval;

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    if (startDate && endDate) {
      return {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate, endDate },
      };
    }

    return DateRangeShortcuts[0];
  });
  const [currentPeriod, setCurrentPeriod] = useState<string>();
  const [startDate, setStartDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local()
        .minus({ days: interval.value })
        .startOf('day')
        .toISO({ includeOffset: false });
    }
    return interval.dateRange?.startDate;
  });
  const [endDate, setEndDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local().startOf('day').toISO({ includeOffset: false });
    }
    return interval.dateRange?.endDate;
  });

  //Refs
  // Ref to store the previous interval for comparison
  const prevIntervalRef = useRef(interval);

  // Ref to track if it's the initial mount
  const isInitialMount = useRef(true);

  // Effects
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        if (urlParams.orgId && !organization) {
          const organizationsResponse = await fetchOrganizationsData({
            queryParams: { include: 'subscription' },
            urlParams: {},
          });
          const org = organizationsResponse.data.find(
            (org) => org.organizationId === urlParams.orgId,
          );
          if (!org) navigate('/');
          if (org?.subscription?.status !== 'active' && !location.pathname.includes('onboarding')) {
            navigate('/onboarding');
          }
          setOrganization(org);
        }
      } catch (err) {
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (interval.code === 'custom' && interval.dateRange) {
      setCurrentPeriod(formatDateRange(interval.dateRange));
    }
  }, [interval]);

  useEffect(() => {
    const fetchMetrics = async () => {
      const metricData = await Promise.all(
        METRIC_RANGE.map(async (range) => {
          const startDate = DateTime.local()
            .minus({ ['days']: range.inputValue })
            .startOf('day')
            .toISO({ includeOffset: false });
          const endDate = DateTime.local().startOf('day').toISO({ includeOffset: false });
          const { data } = await fetchMerchantCount({
            queryParams: {
              'filter[organizationId]': organization?.organizationId,
              'filter[startDate]': startDate,
              'filter[endDate]': endDate,
            },
            urlParams: {},
          });
          return {
            value: +(data.totalMerchantCount / 24).toFixed(2),
            label: range.label,
            units: range.units,
            inputValue: range.inputValue,
          };
        }),
      );
      setMetrics(metricData.sort((a, b) => a.inputValue - b.inputValue));
      setIsMetricsLoading(false);
    };
    fetchMetrics();
  }, [organization]);

  useEffect(() => {
    const fetchMerchants = async () => {
      if (!organization?.organizationId) return;
      const merchantListParams = {
        queryParams: {
          'filter[organizationId]': organization?.organizationId,
          'filter[startDate]': startDate,
          'filter[endDate]': endDate,
        },
        urlParams: {},
      };
      await fetchMerchantsData(merchantListParams);

      const merchantAnalyticsParams = {
        queryParams: {
          'filter[organizationId]': organization?.organizationId,
          'filter[startDate]': startDate,
          'filter[endDate]': endDate,
        },
        urlParams: {},
      };
      setMerchantCount(null);
      fetchMerchantCount(merchantAnalyticsParams);
    };
    fetchMerchants();
  }, [organization, searchText, startDate, endDate]);

  const fuse = new Fuse(merchants, {
    keys: ['name'],
    threshold: 0.3, 
  });

  useEffect(() => {
    if (searchText) {
      const result = fuse.search(searchText);
      setFilteredMerchants(result.map((item) => item.item)); 
    } else {
      setFilteredMerchants(merchants); 
    }
  }, [searchText, merchants]);

  useEffect(() => {
    if (interval.code === 'custom') {
      setIsCustomDateRangePickOpen(true);
    } else {
      setIsCustomDateRangePickOpen(false);
    }
  }, [interval]);

  // Effect for updating query params when interval changes
  useEffect(() => {
    // Skip the effect on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // Check if the interval has actually changed
    if (
      interval.code !== prevIntervalRef.current.code ||
      (interval.code === 'custom' &&
        (interval.dateRange?.startDate !== prevIntervalRef.current.dateRange?.startDate ||
          interval.dateRange?.endDate !== prevIntervalRef.current.dateRange?.endDate))
    ) {
      if (interval.code !== 'custom') {
        navigate(`?interval=${interval.code}`);
      } else if (interval.dateRange) {
        navigate(
          `?startDate=${interval.dateRange.startDate}&endDate=${interval.dateRange.endDate}`,
        );
      }

      // Update the ref with the current interval
      prevIntervalRef.current = interval;
    }
  }, [interval, navigate]);

  // Effect for updating dates when location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const intervalCode = searchParams.get('interval');
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    let newInterval;
    let newStartDate: string;
    let newEndDate: string;

    if (intervalCode) {
      newInterval =
        DateRangeShortcuts.find((item) => item.code === intervalCode) || DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    } else if (startDateParam && endDateParam) {
      newInterval = {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate: startDateParam, endDate: endDateParam },
      };
      newStartDate = startDateParam;
      newEndDate = endDateParam;
    } else {
      // Default case
      newInterval = DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    }

    setInterval(newInterval);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [location.search]);

  // Handlers
  const handleApplyFilter = async () => {
    setLoading(true);
    const merchantListParams: RequestParams = {
      queryParams: {
        'filter[startDate]': startDate,
        'filter[endDate]': endDate,
        'filter[status]': filter.status.join(','),
        'filter[organizationId]': organization?.organizationId,
      },
      urlParams: {},
    };

    const filteredQueryParams = Object.fromEntries(
      Object.entries(merchantListParams.queryParams).filter(([, value]) => value),
    );
    setIsFilterOpen(false);
    await fetchMerchantsData({
      queryParams: filteredQueryParams,
      urlParams: merchantListParams.urlParams,
    });
    setLoading(false);
  };

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: selectedStatus.map((status) => status.value),
    }));
  }, [selectedStatus]);

  //Constants
  const columns: Column<any>[] = [
    {
      header: 'Merchant Name',
      accessor: (row: any) => (
        <div className=' pl-7  text-start w-fit '>
          <a
            href={`/${organization?.organizationId}/clients`}
            className='text-hopstack-blue-700 underline  '
          >
            {row.name}
          </a>
        </div>
      ),
      headerClassnamePerColumn: 'inline flex pl-10  ',
    },
    {
      header: 'Joining Date',
      accessor: (row: any) => formatDate(row.createdAt),
    },

    {
      header: 'Status',
      accessor: (row: any) => (
        <div
          className={`capitalize ${getStatusStyles(
            row.status,
          )} rounded-full py-1 w-fit px-2 inline-flex`}
        >
          {row.status}
        </div>
      ),
    },
    {
      header: 'Duration',
      accessor: (row) => formatDuration(new Date(row.createdAt), new Date()),
    },

    {
      header: 'Action',
      accessor: () => (
        <a href={`/${organization?.organizationId}/clients`} className='text-gray-600'>
          →
        </a>
      ),
    },
  ];

  //Handlers
  const handleFilterPanel = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleGoBack = () => navigate(-1);

  return (
    <>
      <div className='font-bold text-2xl mt-10 px-3'>Dashboard</div>
      <div className='relative'>
        <button onClick={handleGoBack}>
          <ChevronLeftIcon className='h-[16px] stroke-[3px] text-gray-900 pl-5 mt-[10px] absolute' />
        </button>
        <div className=' px-3 mx-10 '>
          <div className='font-semibold text-md mb-2'>Merchants Onboarded </div>
          <div className=' mb-3 '>
            <div className='w-full  inline-flex  justify-between p-2 '>
              <div className='font-semibold text-gray-500  inline-flex'>
                New Merchants{' '}
                <span className='font-bold text-hopstack-blue-700 px-2'>
                  {merchantCount ? (
                    `${merchantCount.totalMerchantCount}`
                  ) : (
                    <Shimmer
                      height='h-[1.4rem] w-[5rem]'
                      className='rounded-md shadow-lg '
                      backgroundColor='bg-hopstack-blue-100 opacity-50'
                    />
                  )}{' '}
                </span>
              </div>
              <div className='w-fit inline-flex justify-end p-2 items-center -mt-4'>
                <div className='relative'>
                  <Datepicker
                    value={{
                      startDate: null,
                      endDate: null,
                    }}
                    onChange={(range: DateRange) => {
                      setInterval({
                        code: 'custom',
                        dateRange: {
                          startDate: DateTime.fromFormat(range.startDate, 'yyyy-MM-dd')
                            .startOf('day')
                            .toFormat('yyyy-MM-dd HH:mm:ss'),
                          endDate: DateTime.fromFormat(range.endDate, 'yyyy-MM-dd')
                            .startOf('day')
                            .toFormat('yyyy-MM-dd HH:mm:ss'),
                        },
                      });
                    }}
                    dateLooking='forward'
                    placeholder={currentPeriod}
                    inputClassName={
                      'bg-white w-[200px] text-sm px-2 cursor-pointer disabled caret-transparent focus:outline-none rounded-md '
                    }
                    containerClassName={`bg-white rounded-md absolute py-1 -mt-4 ${
                      !isCustomDateRangePickerOpen ? 'opacity-0 -mt-[10rem]' : ''
                    }`}
                    toggleClassName={'bg-white rounded-md -mr-2 p-3 relative hidden'}
                    maxDate={new Date()}
                    toggleIcon={() => {
                      return <ChevronDownIcon height={10} />;
                    }}
                    primaryColor={'blue'}
                    startFrom={new Date(new Date().setMonth(new Date().getMonth() - 1))}
                  />
                </div>
                <Listbox value={interval} onChange={setInterval}>
                  <ListboxButton
                    className={
                      'bg-white py-1.5 px-2 rounded-lg inline-flex gap-3 w-[220px] justify-between text-blue-950 items-center'
                    }
                  >
                    {interval.label}
                    <ChevronDownIcon height={10} />
                  </ListboxButton>
                  <Transition
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <ListboxOptions
                      anchor='bottom'
                      className={
                        'bg-white py-1 px-2 rounded-lg w-[10rem] text-blue-950 shadow-lg mt-1'
                      }
                    >
                      {DateRangeShortcuts.map((range) => (
                        <ListboxOption
                          key={range.code}
                          value={range}
                          className='data-[focus]:bg-blue-50 rounded px-2 py-1'
                        >
                          {range.label}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
            </div>
            <div>
              <MetricDisplay metrics={metrics} isLoading={isMetricsLoading} />
            </div>

            <div className='flex gap-3 py-2'>
              <div
                className='p-2 bg-white w-fit rounded-md shadow-lg  border border-gray-300 '
                onClick={handleFilterPanel}
              >
                <FunnelIcon className='h-[25px] text-gray-700/85' />
              </div>
              <div className='w-full'>
                <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-lg w-full rounded-md'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
                  </div>
                  <input
                    id='search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className='block w-full h-10 rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                    placeholder='Search'
                    type='search'
                    name='search'
                  />
                </div>
              </div>
            </div>
            {isFilterOpen && (
              <div className='w-[22rem] absolute z-10'>
                <FilterPanel
                  onCancel={() => {
                    setIsFilterOpen(false);
                  }}
                  onApplyFilters={handleApplyFilter}
                  handleResetFilters={() => {
                    setSelectedStatus([]);
                  }}
                  classNames={{ panel: 'p-4 ', resetButton: 'text-red-600 mr-3' }}
                >
                  <div className='border-b mb-2'>
                    <CheckboxFilter
                      onChange={(merchants: any) => {
                        setSelectedStatus(merchants);
                      }}
                      options={['active', 'inactive'].map((status) => {
                        return {
                          value: status,
                          label: status,
                        };
                      })}
                      selectedOptions={selectedStatus}
                      label='Status'
                    />
                  </div>
                </FilterPanel>
              </div>
            )}
            <div className='mt-2 '>
              <DynamicTable
                columns={columns}
                data={filteredMerchants}
                headerClassname='!text-center'
                rowsClassname='text-center w-4  '
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
