// import { useUser } from '@clerk/clerk-react'
import React, { useContext, useEffect, useRef, useState } from 'react';
import BaseContext from '../components/common/BaseContext';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Warehouse } from '../components/common/types';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Datepicker from 'react-tailwindcss-datepicker';
import Shimmer from '../components/common/Shimmer';
import { DateTime } from 'luxon';

// //Constants
export enum CountListViewTypes {
  OUTBOUND_FULFILLMENT = 'outbound-fulfillment',
  MERCHANTS = 'merchant',
  INBOUND = 'inbound',
}
export enum ListViewTypes {
  DOCK_TO_STOCK = 'dock-to-stock',
  INVENTORY_DISPATCH = 'inventory-dispatch',
  SHIPPING_COST = 'shipping-cost',
  SHIPPED_UNITS = 'shipped-units',
}

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import LogisticOverview from '../components/Dashboard/LogisticOverview';
import InventoryCard from '../components/Dashboard/InventoryCard';
import CountOverview from '../components/Dashboard/CountOverview';
import {
  DateRange,
  DateRangeShortcut,
  DateRangeShortcuts,
  formatDateRange,
} from '../utils/DateRange';

interface InventoryItem {
  label: string;
  tag: string;
  keyName: string;
  colorClass: string;
}

export interface FulfillmentAnalytics {
  averageInventoryDispatchTimeInHours: string;
  percentChangeDispatchInventory: string;
  totalShippingCost: string;
  percentChangeShippingCost: string;
  totalUnitsShipped: string;
  percentChangeTotalUnitsShipped: string;
  uncompletedFulfillments: string;
}

export interface InboundAnalytics {
  averageDockToStockTimeInHours: string;
  percentChangeDockToStockTime: string;
  unprocessedInboundOrderCount: string;
}

export interface MerchantCount {
  totalMerchantCount: number;
}

const inventory: InventoryItem[] = [
  {
    label: 'Expected',
    keyName: 'expectedUnits',
    tag: 'Unprocessed',
    colorClass: 'bg-yellow-100 text-yellow-600',
  },
  {
    label: 'Received',
    keyName: 'receivedUnits',
    tag: 'Receiving Started',
    colorClass: 'bg-green-100 text-green-700',
  },
  {
    label: 'Ready to Ship',
    keyName: 'readyToShipUnits',
    tag: 'Dispatch Awaiting',
    colorClass: 'bg-blue-100 text-blue-900',
  },
  {
    label: 'Processing',
    keyName: 'allocatedUnits',
    tag: 'Allocated',
    colorClass: 'bg-purple-100 text-purple-700',
  },
  {
    label: 'Damaged Inventory',
    keyName: 'damagedUnits',
    tag: 'Damaged',
    colorClass: 'bg-red-100 text-red-700',
  },
];

//Helper functions

export const getUrlParams = (selectedDateRange: DateRange) => {
  if (!selectedDateRange) return {};
  const searchParams = new URLSearchParams();
  searchParams.append('startDate', selectedDateRange.startDate);
  searchParams.append('endDate', selectedDateRange.endDate);

  return searchParams.toString();
};

function Dashboard() {
  // Hooks
  const { organization, setLoading, setOrganization } = useContext(BaseContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // API requests
  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { data: warehouses, executeRequest: fetchWarehousesData } = useRequest<Warehouse[]>(
    `${apiPaths.GET_WAREHOUSES}`,
    [],
  );

  const { data: inventoryUnits, executeRequest: fetchInventoryUnits } = useRequest(
    `/api/v1/inventory/status-count`,
    {},
  );
  const {
    data: inboundAnalytics,
    executeRequest: fetchInboundAnalytics,
    setData: setInboundAnalytics,
  } = useRequest<InboundAnalytics>(`/api/v1/inbound-orders/analytics`);
  const {
    data: fulfillmentAnalytics,
    executeRequest: fetchFulfillmentAnalytics,
    setData: setFulfillmentAnalytics,
  } = useRequest<FulfillmentAnalytics>(`/api/v1/fulfillments/analytics`);
  const {
    data: merchantCount,
    executeRequest: fetchMerchantCount,
    setData: setMerchantCount,
  } = useRequest<MerchantCount>(`/api/v1/merchants/count`);

  //State
  const [interval, setInterval] = useState<DateRangeShortcut>(() => {
    const savedInterval = DateRangeShortcuts.find(
      (item) => item.code === searchParams.get('interval'),
    );
    if (savedInterval) return savedInterval;

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    if (startDate && endDate) {
      return {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate, endDate },
      };
    }

    return DateRangeShortcuts[0];
  });

  const [currentPeriod, setCurrentPeriod] = useState<string>();
  const [startDate, setStartDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local()
        .minus({ days: interval.value })
        .startOf('day')
        .toISO({ includeOffset: false });
    }
    return interval.dateRange?.startDate;
  });
  const [endDate, setEndDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local().startOf('day').toISO({ includeOffset: false });
    }
    return interval.dateRange?.endDate;
  });
  const [isCustomDateRangePickerOpen, setIsCustomDateRangePickOpen] = useState<boolean>(false);

  //Refs
  // Ref to store the previous interval for comparison
  const prevIntervalRef = useRef(interval);

  // Ref to track if it's the initial mount
  const isInitialMount = useRef(true);

  // Effects
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        if (urlParams.orgId && !organization) {
          const organizationsResponse = await fetchOrganizationsData({
            queryParams: { include: 'subscription' },
            urlParams: {},
          });
          const org = organizationsResponse.data.find(
            (org) => org.organizationId === urlParams.orgId,
          );
          if (!org) navigate('/');
          if (org?.subscription?.status !== 'active' && !location.pathname.includes('onboarding')) {
            navigate('/onboarding');
          }
          setOrganization(org);
        }
        await fetchWarehousesData({
          queryParams: { 'filter[organizationId]': urlParams.orgId },
          urlParams: {},
        });
      } catch (err) {
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const warehouseIds = warehouses.map((warehouse) => warehouse.warehouseId);

    const fetchInboundAnalyticsParams: RequestParams = {
      queryParams: {
        'filter[warehouseId]': warehouseIds.join(','),
        'filter[startDate]': startDate,
        'filter[endDate]': endDate,
      },
      urlParams: {},
    };

    const fetchInventoryUnitsParams: RequestParams = {
      queryParams: {
        'filter[warehouseId]': warehouseIds.join(','),
      },
      urlParams: {},
    };

    const fetchFulfillmentAnalyticsParams: RequestParams = {
      queryParams: {
        'filter[warehouseId]': warehouseIds.join(','),
        'filter[startDate]': startDate,
        'filter[endDate]': endDate,
      },
      urlParams: {},
    };

    const fetchMerchantCountParams: RequestParams = {
      queryParams: {
        'filter[organizationId]': organization?.organizationId,
        'filter[startDate]': startDate,
        'filter[endDate]': endDate,
      },
      urlParams: {},
    };

    if (warehouses.length) {
      setInboundAnalytics(null);
      setFulfillmentAnalytics(null);
      setMerchantCount(null);
      Promise.all([
        fetchInventoryUnits(fetchInventoryUnitsParams),
        fetchInboundAnalytics(fetchInboundAnalyticsParams),
        fetchFulfillmentAnalytics(fetchFulfillmentAnalyticsParams),
        fetchMerchantCount(fetchMerchantCountParams),
      ]).catch((error) => {
        // Handle any errors that occurred during the parallel requests
        console.error(error);
      });
    }
  }, [warehouses, interval, startDate, endDate]);

  useEffect(() => {
    if (interval.code === 'custom' && interval.dateRange) {
      setCurrentPeriod(formatDateRange(interval.dateRange));
    } else {
      setCurrentPeriod('Click to select custom range');
    }
  }, [interval]);

  useEffect(() => {
    if (interval.code === 'custom') {
      setIsCustomDateRangePickOpen(true);
    } else {
      setIsCustomDateRangePickOpen(false);
    }
  }, [interval]);

  // Effect for updating query params when interval changes
  useEffect(() => {
    // Skip the effect on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // Check if the interval has actually changed
    if (
      interval.code !== prevIntervalRef.current.code ||
      (interval.code === 'custom' &&
        (interval.dateRange?.startDate !== prevIntervalRef.current.dateRange?.startDate ||
          interval.dateRange?.endDate !== prevIntervalRef.current.dateRange?.endDate))
    ) {
      if (interval.code !== 'custom') {
        navigate(`?interval=${interval.code}`);
      } else if (interval.dateRange) {
        navigate(
          `?startDate=${interval.dateRange.startDate}&endDate=${interval.dateRange.endDate}`,
        );
      }

      // Update the ref with the current interval
      prevIntervalRef.current = interval;
    }
  }, [interval, navigate]);

  // Effect for updating dates when location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const intervalCode = searchParams.get('interval');
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    let newInterval;
    let newStartDate: string;
    let newEndDate: string;

    if (intervalCode) {
      newInterval =
        DateRangeShortcuts.find((item) => item.code === intervalCode) || DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    } else if (startDateParam && endDateParam) {
      newInterval = {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate: startDateParam, endDate: endDateParam },
      };
      newStartDate = startDateParam;
      newEndDate = endDateParam;
    } else {
      // Default case
      newInterval = DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    }

    setInterval(newInterval);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [location.search]);

  return (
    <div className='bg-gray-50 text-blue-950 transition-all duration-300	 p-6 grid row-span-3 rounded-lg shadow-lg'>
      <h2 className='text-2xl text-hopstack-blue-900 font-semibold mb-4'>Dashboard</h2>
      {/* Inventory Overview */}
      <div className=''>
        <div className='p-4 bg-hopstack-blue-50 rounded-lg'>
          <h3 className='text-lg text-hopstack-blue-900 font-semibold mb-2'>Inventory Overview</h3>

          <div className='grid grid-cols-5 gap-4 mt-4 '>
            {inventory.map((item, index) =>
              Object.keys(inventoryUnits).length == 0 ? (
                <Shimmer
                  key={'inv.' + index}
                  height='h-[8rem]'
                  className='rounded-md shadow-lg'
                  backgroundColor='bg-slate-300 opacity-50'
                />
              ) : (
                <InventoryCard
                  key={index + 'inv'}
                  item={item}
                  index={index}
                  inventoryUnits={inventoryUnits}
                />
              ),
            )}
          </div>
        </div>
      </div>

      <div>
        <div className='w-full inline-flex justify-end p-2 items-center'>
          <div className='relative '>
            <Datepicker
              value={{
                startDate: null,
                endDate: null,
              }}
              onChange={(range: DateRange) => {
                setInterval({
                  code: 'custom',
                  dateRange: {
                    startDate: DateTime.fromFormat(range.startDate, 'yyyy-MM-dd')
                      .startOf('day')
                      .toFormat('yyyy-MM-dd HH:mm:ss'),
                    endDate: DateTime.fromFormat(range.endDate, 'yyyy-MM-dd')
                      .startOf('day')
                      .toFormat('yyyy-MM-dd HH:mm:ss'),
                  },
                });
              }}
              dateLooking='forward'
              placeholder={currentPeriod}
              inputClassName={
                'bg-white w-[200px] text-sm px-2 cursor-pointer disabled caret-transparent focus:outline-none rounded-md '
              }
              containerClassName={`bg-white rounded-md absolute py-1 -mt-4 ${
                !isCustomDateRangePickerOpen ? 'opacity-0 -mt-[10rem]' : ''
              }`}
              toggleClassName={'bg-white rounded-md -mr-2 p-3 relative hidden'}
              maxDate={new Date()}
              toggleIcon={() => {
                return <ChevronDownIcon height={10} />;
              }}
              primaryColor={'blue'}
              startFrom={new Date(new Date().setMonth(new Date().getMonth() - 1))}
            />
          </div>
          <Listbox value={interval} onChange={setInterval}>
            <ListboxButton
              className={
                'bg-white py-1.5 px-2 rounded-lg inline-flex gap-3 w-[220px] justify-between text-blue-950 items-center'
              }
            >
              {interval.label}
              <ChevronDownIcon height={10} />
            </ListboxButton>
            <Transition
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <ListboxOptions
                anchor='bottom'
                className={'bg-white py-1 px-2 rounded-lg w-[10rem] text-blue-950 shadow-lg mt-1'}
              >
                {DateRangeShortcuts.map((range) => (
                  <ListboxOption
                    key={range.code}
                    value={range}
                    className='data-[focus]:bg-blue-50 rounded px-2 py-1'
                  >
                    {range.label}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </Listbox>
        </div>

        {/* Logistics Overview */}
        <div className='bg-hopstack-blue-50 p-4 rounded-lg'>
          <h3 className='text-lg text-hopstack-blue-900 font-semibold mb-4  '>
            Logistics Overview
          </h3>
          <div className='grid grid-cols-2 gap-4 justify-between '>
            <LogisticOverview
              item={{
                label: 'Dock to Stock Time',
                value: inboundAnalytics
                  ? +inboundAnalytics.averageDockToStockTimeInHours > 24
                    ? `${(+inboundAnalytics.averageDockToStockTimeInHours / 24).toFixed(2)} Days`
                    : `${(+inboundAnalytics.averageDockToStockTimeInHours).toFixed(2)} Hours`
                  : null,
                change: inboundAnalytics
                  ? `${(+inboundAnalytics.percentChangeDockToStockTime).toFixed(2)}%`
                  : null,
                period:
                  currentPeriod === 'Click to select custom range'
                    ? 'Pick a custom range first'
                    : currentPeriod,
                description: (
                  <>
                    Average time taken to move an inbound order from{' '}
                    <span className='text-hopstack-blue-700 font-semibold'>Receiving Started</span>{' '}
                    to
                    <span className='text-hopstack-blue-700 font-semibold'> Completed</span>.
                  </>
                ),
              }}
              isLoading={!inboundAnalytics ? true : false}
              isReversePrimaryColor={true}
              path={`logistics/${ListViewTypes.DOCK_TO_STOCK}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
            <LogisticOverview
              item={{
                label: 'Inventory Dispatch Time',
                value: fulfillmentAnalytics
                  ? +fulfillmentAnalytics.averageInventoryDispatchTimeInHours > 24
                    ? `${(+fulfillmentAnalytics.averageInventoryDispatchTimeInHours / 24).toFixed(
                        2,
                      )} Days`
                    : `${(+fulfillmentAnalytics.averageInventoryDispatchTimeInHours).toFixed(
                        2,
                      )} Hours`
                  : null,
                change: fulfillmentAnalytics
                  ? `${(+fulfillmentAnalytics.percentChangeDispatchInventory).toFixed(2)}%`
                  : null,
                period:
                  currentPeriod === 'Click to select custom range'
                    ? 'Pick a custom range first'
                    : currentPeriod,
                description: (
                  <>
                    Average time taken to move a fulfillment from{' '}
                    <span className='text-hopstack-blue-700 font-semibold'>Unprocessed</span> to{' '}
                    <span className='text-hopstack-blue-700 font-semibold'> Completed</span>.
                  </>
                ),
              }}
              isReversePrimaryColor={true}
              isLoading={!fulfillmentAnalytics ? true : false}
              path={`logistics/${ListViewTypes.INVENTORY_DISPATCH}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
            <LogisticOverview
              item={{
                label: 'Shipping Costs',
                value: fulfillmentAnalytics ? `$ ${fulfillmentAnalytics.totalShippingCost}` : null,
                change: fulfillmentAnalytics
                  ? `${+fulfillmentAnalytics.percentChangeShippingCost}%`
                  : null,
                period:
                  currentPeriod === 'Click to select custom range'
                    ? 'Pick a custom range first'
                    : currentPeriod,
                description: (
                  <>
                    Total shipping costs for all{' '}
                    <span className='text-hopstack-blue-700 font-semibold'> Completed</span> orders
                    in the chosen time period.
                  </>
                ),
              }}
              isLoading={!fulfillmentAnalytics ? true : false}
              isReversePrimaryColor={true}
              path={`logistics/${ListViewTypes.SHIPPING_COST}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
            <LogisticOverview
              item={{
                label: 'Shipped Units',
                value: fulfillmentAnalytics
                  ? fulfillmentAnalytics?.totalUnitsShipped + ' Units'
                  : null,
                change: fulfillmentAnalytics
                  ? `${+fulfillmentAnalytics.percentChangeTotalUnitsShipped}%`
                  : null,
                period:
                  currentPeriod === 'Click to select custom range'
                    ? 'Pick a custom range first'
                    : currentPeriod,
                description: (
                  <>
                    Total count of units{' '}
                    <span className='text-hopstack-blue-700 font-semibold'> Shipped</span> in the
                    selected time period.
                  </>
                ),
              }}
              isLoading={!fulfillmentAnalytics ? true : false}
              path={`logistics/${ListViewTypes.SHIPPED_UNITS}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
          </div>
        </div>
        {/* Counts */}
        <div className='mt-8   rounded-lg'>
          <div className='grid grid-cols-3 gap-4  '>
            <CountOverview
              item={{
                label: 'Inbound',
                value: inboundAnalytics && +inboundAnalytics.unprocessedInboundOrderCount,
                period: currentPeriod,
                description: (
                  <>
                    Count of all
                    <span className='text-hopstack-blue-700 font-semibold'>
                      {' '}
                      unprocessed orders{' '}
                    </span>{' '}
                    where consignment date is in the selected time period.
                  </>
                ),
              }}
              isLoading={!inboundAnalytics ? true : false}
              path={`count/${CountListViewTypes.INBOUND}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
            <CountOverview
              item={{
                label: 'Outbound',
                value: fulfillmentAnalytics && +fulfillmentAnalytics.uncompletedFulfillments,
                period: currentPeriod,
                description: (
                  <>
                    Count of{' '}
                    <span className='text-hopstack-blue-700 font-semibold'>
                      {' '}
                      outbound fulfillments
                    </span>{' '}
                    excluding those with a status of Completed.
                    {/* <div className='hidden 2xl:invisible'>******</div> */}
                  </>
                ),
              }}
              isLoading={!fulfillmentAnalytics ? true : false}
              path={`count/${CountListViewTypes.OUTBOUND_FULFILLMENT}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
            <CountOverview
              item={{
                label: 'Merchants Onboarded',
                value: merchantCount && merchantCount.totalMerchantCount,
                period: currentPeriod,
                description: (
                  <>
                    Count of{' '}
                    <span className='text-hopstack-blue-700 font-semibold'> Merchants</span>{' '}
                    onboarded for the selected time period.
                    {/* <div className='invisible'>******</div> */}
                  </>
                ),
              }}
              isLoading={!merchantCount ? true : false}
              path={`count/${CountListViewTypes.MERCHANTS}?${
                interval.code === 'custom'
                  ? getUrlParams(interval.dateRange)
                  : `interval=${interval.code}`
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
