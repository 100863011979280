import React, { useContext, useEffect, useState } from 'react';

import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import BaseLayout from './pages/BaseLayout';
import Orders from './pages/Orders';
import GetStarted from './pages/GetStarted';
import ClientManagement from './pages/ClientManagement';
import Organizations from './pages/Organizations';
import BaseContext from './components/common/BaseContext';
import Inbound from './pages/Inbound';
import AmazonAuthRedirection from './pages/AmazonAuthRedirection';
import Products from './pages/Products';
import Inventory from './pages/Inventory';
import InboundDetail from './pages/InboundDetail';
import Outbound from './pages/Outbound';
import Fulfillment from './pages/Fulfillment';
import FulfillmentDetail from './pages/FulfillmentDetail';
import Notifications from './pages/Notifications';
import { roles } from './components/common/utils';
import LoginPage from './pages/Login';
import SignUpPage from './pages/SignUpPage';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import { DockToStock } from './components/DashboardList/DockToStock';
import { InboundCount } from './components/DashboardList/InboundCount';
import { InventoryDispatch } from './components/DashboardList/InventoryDispatch';
import { ShippingCost } from './components/DashboardList/ShippingCost';
import { Shippedunits } from './components/DashboardList/ShippedUnits';
import { OutboundFulfillment } from './components/DashboardList/OutboundFulfillment';
import { Merchants } from './components/DashboardList/Merchants';
import LandingPage from './pages/LandingPage';
import useRequest, { RequestParams } from './hooks/useRequest';
import { apiPaths } from './utils/ApiPaths';
import Connections from './pages/Connections';
import ConnectionDetails from './pages/ConnectionDetails';
import ShopifyAuthRedirection from './pages/ShopifyAuthRedirection';
import OutboundDetail from './pages/OutboundDetails';
function App() {
  return (
    <div className='flex h-screen bg-gray-100'>
      <SignedIn>
        <Welcome />
      </SignedIn>
      <SignedOut>
        <BrowserRouter>
          <BaseLayout hideSidebar={true}>
            <Routes>
              <Route path='/*' element={<LoginPage />} />
              <Route path='/signup' element={<HandleSignUp />} />
            </Routes>
          </BaseLayout>
        </BrowserRouter>
      </SignedOut>
    </div>
  );
}

function HandleSignUp() {
  const [searchParams] = useSearchParams();
  const inviteEmailToken = searchParams.get('inviteToken');
  if (inviteEmailToken) {
    const inviteEmail = atob(inviteEmailToken);
    return <SignUpPage initialValues={{ emailAddress: inviteEmail }} />;
  }
  return <SignUpPage />;
}

function Welcome() {
  const [showNotification, setShowNotification] = useState({ show: false, type: '', content: '' });
  const [loading, setLoading] = useState(false);
  const [orgRole, setOrgRole] = useState<roles | null>(null);
  const [org, setOrg] = useState(null);

  return (
    <BrowserRouter>
      <BaseContext.Provider
        value={{
          organization: org,
          setOrganization: setOrg,
          showNotification: showNotification,
          setShowNotification: setShowNotification,
          loading: loading,
          setLoading: setLoading,
          setOrgRole: setOrgRole,
          orgRole: orgRole,
        }}
      >
        <BaseLayout>
          <Routes>
            {/* Open Routes */}
            <Route path='/' element={<LandingPage />} />
            <Route path='/oauth/sp-api' element={<AmazonAuthRedirection />} />
            <Route path='/oauth/shopify' element={<ShopifyAuthRedirection />} />

            {/* Organization Routes */}
            <Route path='/:orgId/*' element={<OrgSpecificRoutes />}></Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BaseLayout>
      </BaseContext.Provider>
    </BrowserRouter>
  );
}

export default App;

function OrgSpecificRoutes() {
  const { setLoading, setOrganization, setOrgRole } = useContext(BaseContext);
  const navigate = useNavigate();
  const { orgId } = useParams();

  const { executeRequest: fetchOrganization } = useRequest(apiPaths.GET_ORGANIZATIONS + `/{orgId}`);
  const { executeRequest: fetchUser } = useRequest('/api/v1/users/me');
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        setLoading(true);
        const params: RequestParams = {
          queryParams: {
            include: 'subscription',
          },
          urlParams: { orgId },
        };
        const organizationResponse = await fetchOrganization(params);
        if (organizationResponse.success) {
          setOrganization(organizationResponse.data);
          const { subscription } = organizationResponse.data;
          if (subscription && subscription.status !== 'active') {
            navigate('/');
            return;
          }
          const { data: user } = await fetchUser({ queryParams: {}, urlParams: {} });
          const role = Object.keys(user.role[organizationResponse.data.organizationId])[0];
          setOrgRole(role);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, []);

  return (
    <Routes>
      <Route path='' element={<GetStarted />} />
      {/* Dashboard Routes */}
      <Route path='dashboard' element={<Dashboard />} />
      <Route path='dashboard/logistics/dock-to-stock' element={<DockToStock />} />
      <Route path='dashboard/logistics/inventory-dispatch' element={<InventoryDispatch />} />
      <Route path='dashboard/logistics/shipping-cost' element={<ShippingCost />} />
      <Route path='dashboard/logistics/shipped-units' element={<Shippedunits />} />
      <Route path='dashboard/count/inbound' element={<InboundCount />} />
      <Route path='dashboard/count/outbound-fulfillment' element={<OutboundFulfillment />} />
      <Route path='dashboard/count/merchant' element={<Merchants />} />
      {/* Other Routes */}
      <Route path='orders' element={<Orders />} />
      <Route path='products' element={<Products />} />
      <Route path='inventory' element={<Inventory />} />
      <Route path='inbound' element={<Inbound />} />
      <Route path='inbound/:orderId' element={<InboundDetail />} />
      <Route path='outbound' element={<Outbound />} />
      <Route path='outbound/:orderId' element={<OutboundDetail />} />
      <Route path='fulfillment/:orderId' element={<FulfillmentDetail />} />
      <Route path='fulfillment' element={<Fulfillment />} />
      <Route path='connections' element={<Connections />} />
      <Route path='connections/:integrationId' element={<ConnectionDetails />} />{' '}
      <Route path='notifications' element={<Notifications />} />
      <Route path='clients' element={<ClientManagement />} />
      <Route path='organizations' element={<Organizations />} />
    </Routes>
  );
}
