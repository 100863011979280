import React, { useContext, useEffect, useRef, useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateTime } from 'luxon';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useRequest, { RequestParams } from '../../hooks/useRequest';
import { InboundAnalytics } from '../../pages/Dashboard';
import { apiPaths } from '../../utils/ApiPaths';
import {
  DateRange,
  DateRangeShortcut,
  DateRangeShortcuts,
  formatDateRange,
} from '../../utils/DateRange';
import BaseContext from '../common/BaseContext';
import { Merchant, Warehouse } from '../common/types';
import {
  Listbox,
  ListboxButton,
  Transition,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import DynamicTable from '../common/DynamicTable';
import Shimmer from '../common/Shimmer';
import CheckboxFilter from './Filter/CheckboxFilter';
import RangeSliderFilter from './Filter/RangeSliderFilter';
import FilterPanel from './FilterPanel';
import MetricDisplay from './MetricDisplay';

// Types
type Metric = {
  value: number;
  label: string;
  units: string;
  inputValue: number;
};

// Constants
const METRIC_RANGE = [
  { inputValue: 1, label: 'Today', units: 'Units' },
  { inputValue: 7, label: 'Last 7 Days', units: 'Units' },
  { inputValue: 15, label: 'Last 15 Days', units: 'Units' },
  { inputValue: 30, label: 'Last 30 Days', units: 'Units' },
];

// Helper functions
const formatDate = (date: string): string => {
  return DateTime.fromJSDate(new Date(date)).toFormat('dd MMM yyyy');
};

export const InboundCount: React.FC = () => {
  // Hooks
  const { organization, setLoading, setOrganization } = useContext(BaseContext);
  const urlParams = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // API requests
  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { executeRequest: fetchMerchantsData, data: merchants } = useRequest<Merchant[]>(
    apiPaths.GET_MERCHANTS,
    [],
  );
  const { data: warehouses, executeRequest: fetchWarehousesData } = useRequest<Warehouse[]>(
    apiPaths.GET_WAREHOUSES,
    [],
  );
  const {
    data: inboundAnalytics,
    executeRequest: fetchInboundAnalytics,
    setData: setInboundAnalytics,
  } = useRequest<InboundAnalytics>('/api/v1/inbound-orders/analytics');
  const { data: orders, executeRequest: fetchOrdersData } = useRequest(apiPaths.INBOUND_ORDERS, []);
  const { executeRequest: fetchExpectedUnits } = useRequest<InboundAnalytics>(
    `/api/v1/inbound-orders/expected-units`,
  );
  // State
  const [metrics, setMetrics] = useState<Metric[]>(
    METRIC_RANGE.map((range) => ({ ...range, value: 0 })),
  );
  const [isMetricsLoading, setIsMetricsLoading] = useState<boolean>(true);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [selectedMerchants, setSelectedMerchants] = useState<{ value: string; label: string }[]>(
    [],
  );
  const [isCustomDateRangePickerOpen, setIsCustomDateRangePickOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilters] = useState<{
    merchantId: string[];
    expectedUnits: {
      min: string;
      max: string;
    };
  }>();
  const [expectedUnit, setExpectedUnits] = useState<{ min?: number; max?: number }>({});

  const [interval, setInterval] = useState<DateRangeShortcut>(() => {
    const savedInterval = DateRangeShortcuts.find(
      (item) => item.code === searchParams.get('interval'),
    );
    if (savedInterval) return savedInterval;

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');
    if (startDate && endDate) {
      return {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate, endDate },
      };
    }

    return DateRangeShortcuts[0];
  });
  const [currentPeriod, setCurrentPeriod] = useState<string>();
  const [startDate, setStartDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local()
        .minus({ days: interval.value })
        .startOf('day')
        .toISO({ includeOffset: false });
    }
    return interval.dateRange?.startDate;
  });
  const [endDate, setEndDate] = useState<string>(() => {
    if (interval.code !== 'custom') {
      return DateTime.local().startOf('day').toISO({ includeOffset: false });
    }
    return interval.dateRange?.endDate;
  });

  //Refs
  // Ref to store the previous interval for comparison
  const prevIntervalRef = useRef(interval);

  // Ref to track if it's the initial mount
  const isInitialMount = useRef(true);

  // Effects
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        if (urlParams.orgId && !organization) {
          const organizationsResponse = await fetchOrganizationsData({
            queryParams: { include: 'subscription' },
            urlParams: {},
          });
          const org = organizationsResponse.data.find(
            (org) => org.organizationId === urlParams.orgId,
          );
          if (!org) navigate('/');
          if (org?.subscription?.status !== 'active' && !location.pathname.includes('onboarding')) {
            navigate('/onboarding');
          }
          setOrganization(org);
        }
        await fetchWarehousesData({
          queryParams: { 'filter[organizationId]': urlParams.orgId },
          urlParams: {},
        });
        await fetchMerchantsData({
          queryParams: { 'filter[organizationId]': urlParams.orgId },
          urlParams: {},
        });
      } catch (err) {
        navigate('/');
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (interval.code === 'custom' && interval.dateRange) {
      setCurrentPeriod(formatDateRange(interval.dateRange));
    }
  }, [interval]);

  useEffect(() => {
    const fetchMetrics = async () => {
      if (warehouses.length === 0) return;

      const warehouseIds = warehouses.map((warehouse) => warehouse.warehouseId);
      const metricData = await Promise.all(
        METRIC_RANGE.map(async (range) => {
          const startDate = DateTime.local()
            .minus({ ['days']: range.inputValue })
            .startOf('day')
            .toISO({ includeOffset: false });
          const endDate = DateTime.local().startOf('day').toISO({ includeOffset: false });
          const { data } = await fetchExpectedUnits({
            queryParams: {
              'filter[warehouseId]': warehouseIds.join(','),
              'filter[startDate]': startDate,
              'filter[endDate]': endDate,
            },
            urlParams: {},
          });
          return {
            value: data.expectedUnits,
            label: range.label,
            units: range.units,
            inputValue: range.inputValue,
          };
        }),
      );
      setMetrics(metricData.sort((a, b) => a.inputValue - b.inputValue));
      setIsMetricsLoading(false);
    };
    fetchMetrics();
  }, [warehouses]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (warehouses.length === 0 || !organization?.organizationId) return;

      const warehouseIds = warehouses.map((warehouse) => warehouse.warehouseId);
      const inboundListParams = {
        queryParams: {
          'filter[warehouseId]': warehouseIds.join(','),
          'filter[status]': 'unprocessed',
          'filter[startDate]': startDate,
          'filter[endDate]': endDate,
          'filter[organizationId]': organization.organizationId,
          search: searchText,
          include: 'merchant,auditLogs',
        },
        urlParams: {},
      };
      await fetchOrdersData(inboundListParams);

      setInboundAnalytics(null);
      await fetchInboundAnalytics({
        queryParams: {
          'filter[warehouseId]': warehouseIds.join(','),
          'filter[startDate]': startDate,
          'filter[endDate]': endDate,
        },
        urlParams: {},
      });
    };
    fetchOrders();
  }, [warehouses, searchText, startDate, endDate]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      merchantId: selectedMerchants.map((merchant) => merchant.value),
    }));
  }, [selectedMerchants]);

  useEffect(() => {
    if (interval.code === 'custom') {
      setIsCustomDateRangePickOpen(true);
    } else {
      setIsCustomDateRangePickOpen(false);
    }
  }, [interval]);

  // Effect for updating query params when interval changes
  useEffect(() => {
    // Skip the effect on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // Check if the interval has actually changed
    if (
      interval.code !== prevIntervalRef.current.code ||
      (interval.code === 'custom' &&
        (interval.dateRange?.startDate !== prevIntervalRef.current.dateRange?.startDate ||
          interval.dateRange?.endDate !== prevIntervalRef.current.dateRange?.endDate))
    ) {
      if (interval.code !== 'custom') {
        navigate(`?interval=${interval.code}`);
      } else if (interval.dateRange) {
        navigate(
          `?startDate=${interval.dateRange.startDate}&endDate=${interval.dateRange.endDate}`,
        );
      }

      // Update the ref with the current interval
      prevIntervalRef.current = interval;
    }
  }, [interval, navigate]);

  // Effect for updating dates when location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const intervalCode = searchParams.get('interval');
    const startDateParam = searchParams.get('startDate');
    const endDateParam = searchParams.get('endDate');

    let newInterval: DateRangeShortcut;
    let newStartDate: string;
    let newEndDate: string;

    if (intervalCode) {
      newInterval =
        DateRangeShortcuts.find((item) => item.code === intervalCode) || DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    } else if (startDateParam && endDateParam) {
      newInterval = {
        code: 'custom',
        value: 0,
        label: 'Custom Range',
        dateRange: { startDate: startDateParam, endDate: endDateParam },
      };
      newStartDate = startDateParam;
      newEndDate = endDateParam;
    } else {
      // Default case
      newInterval = DateRangeShortcuts[0];
      newStartDate =
        DateTime.local()
          .minus({ days: newInterval.value })
          .startOf('day')
          .toISO({ includeOffset: false }) || '';
      newEndDate = DateTime.local().endOf('day').toISO({ includeOffset: false }) || '';
    }

    setInterval(newInterval);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [location.search]);

  // Handlers
  const handleFilterPanel = () => setIsFilterOpen(!isFilterOpen);
  const handleApplyFilter = async () => {
    setLoading(true);
    const warehouseIds = warehouses.map((warehouse) => warehouse.warehouseId);
    const inboundListParams: RequestParams = {
      queryParams: {
        'filter[warehouseId]': warehouseIds.join(','),
        'filter[status]': 'unprocessed',
        'filter[startDate]': startDate,
        'filter[endDate]': endDate,
        'filter[organizationId]': organization?.organizationId,
        'filter[merchantId]': filter.merchantId.join(','),
        'filter[minExpectedUnits]': filter.expectedUnits?.min ?? '', // Use safe navigation and default to empty string
        'filter[maxExpectedUnits]': filter.expectedUnits?.max ?? '',
        include: 'merchant,auditLogs',
      },
      urlParams: {},
    };

    const filteredQueryParams = Object.fromEntries(
      Object.entries(inboundListParams.queryParams).filter(([, value]) => value),
    );
    setIsFilterOpen(false);
    await fetchOrdersData({
      queryParams: filteredQueryParams,
      urlParams: inboundListParams.urlParams,
    });
    setLoading(false);
  };

  const handleGoBack = () => navigate(-1);

  // Constants
  const columns = [
    {
      header: 'Order ID',
      accessor: (row: any) => (
        <div className=' pl-7 w-[10rem]'>
          <a
            href={`/${organization?.organizationId}/inbound/${row.inboundOrderId}`}
            className='text-hopstack-blue-700 underline '
          >
            {row.inboundOrderDisplayId}
          </a>
        </div>
      ),
      headerClassnamePerColumn: 'inline flex pl-10',
    },
    {
      header: 'Order Date',
      accessor: (row: any) => formatDate(row?.orderDate),
    },
    {
      header: 'Delivery ETA',
      accessor: (row: any) => formatDate(row?.expectedDeliveryDate),
    },
    {
      header: 'Merchant Name',
      accessor: (row: any) => row?.merchant?.name,
    },
    {
      header: 'No. of Units Expected',
      accessor: (row: any) => (
        <div className='inline-flex justify-center w-full'>
          <span className='bg-green-100 h-7 w-10 text-green-700  inline-flex flex-row items-center justify-center  rounded-full'>
            {row.products.reduce((sum, item) => sum + item.expectedQuantity, 0)}
          </span>
        </div>
      ),
    },
    {
      header: 'Action',
      accessor: (row) => (
        <a
          href={`/${organization?.organizationId}/inbound/${row.inboundOrderId}`}
          className='text-gray-600'
        >
          →
        </a>
      ),
    },
  ];
  return (
    <>
      <div className='font-bold text-2xl mt-10 px-3'>Dashboard</div>
      <div className='relative'>
        <button onClick={handleGoBack}>
          <ChevronLeftIcon className='h-[16px] stroke-[3px] text-gray-900 pl-5 mt-[10px] absolute' />
        </button>
        <div className=' px-3 mx-10 '>
          <div className='font-semibold text-md mb-2'>
            Inbound - <span className='text-gray-400  '> Expected Units</span>{' '}
          </div>
          <div className=' mb-3 '>
            <div className='w-full  inline-flex  justify-between p-2 items-center '>
              <div className='font-semibold text-gray-500  inline-flex'>
                Unprocessed Orders{' '}
                <span className='font-bold text-hopstack-blue-700 px-2'>
                  {inboundAnalytics ? (
                    +inboundAnalytics.unprocessedInboundOrderCount
                  ) : (
                    <Shimmer
                      height='h-[1.4rem] w-[5rem]'
                      className='rounded-md shadow-lg '
                      backgroundColor='bg-hopstack-blue-100 opacity-50'
                    />
                  )}{' '}
                </span>
              </div>
              <div className='w-fit inline-flex justify-end p-2 items-center -mt-4'>
                <div className='relative'>
                  <Datepicker
                    value={{
                      startDate: null,
                      endDate: null,
                    }}
                    onChange={(range: DateRange) => {
                      setInterval({
                        code: 'custom',
                        dateRange: {
                          startDate: DateTime.fromFormat(range.startDate, 'yyyy-MM-dd')
                            .startOf('day')
                            .toFormat('yyyy-MM-dd HH:mm:ss'),
                          endDate: DateTime.fromFormat(range.endDate, 'yyyy-MM-dd')
                            .startOf('day')
                            .toFormat('yyyy-MM-dd HH:mm:ss'),
                        },
                      });
                    }}
                    dateLooking='forward'
                    placeholder={currentPeriod}
                    inputClassName={
                      'bg-white w-[200px] text-sm px-2 cursor-pointer disabled caret-transparent focus:outline-none rounded-md '
                    }
                    containerClassName={`bg-white rounded-md absolute py-1 -mt-4 ${
                      !isCustomDateRangePickerOpen ? 'opacity-0 -mt-[10rem]' : ''
                    }`}
                    toggleClassName={'bg-white rounded-md -mr-2 p-3 relative hidden'}
                    maxDate={new Date()}
                    toggleIcon={() => {
                      return <ChevronDownIcon height={10} />;
                    }}
                    primaryColor={'blue'}
                    startFrom={new Date(new Date().setMonth(new Date().getMonth() - 1))}
                  />
                </div>
                <Listbox value={interval} onChange={setInterval}>
                  <ListboxButton
                    className={
                      'bg-white py-1.5 px-2 rounded-lg inline-flex gap-3 w-[220px] justify-between text-blue-950 items-center'
                    }
                  >
                    {interval.label}
                    <ChevronDownIcon height={10} />
                  </ListboxButton>
                  <Transition
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <ListboxOptions
                      anchor='bottom'
                      className={
                        'bg-white py-1 px-2 rounded-lg w-[10rem] text-blue-950 shadow-lg mt-1'
                      }
                    >
                      {DateRangeShortcuts.map((range) => (
                        <ListboxOption
                          key={range.code}
                          value={range}
                          className='data-[focus]:bg-blue-50 rounded px-2 py-1'
                        >
                          {range.label}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </Transition>
                </Listbox>
              </div>
            </div>
            <div>
              <MetricDisplay metrics={metrics} isLoading={isMetricsLoading} />
            </div>

            <div className='flex gap-3 py-2'>
              <div
                className='p-2 bg-white w-fit rounded-md shadow-lg  border border-gray-300 '
                onClick={handleFilterPanel}
              >
                <FunnelIcon className='h-[25px] text-gray-700/85' />
              </div>
              <div className='w-full'>
                <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-lg w-full rounded-md'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
                  </div>
                  <input
                    id='search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className='block w-full h-10 rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                    placeholder='Search'
                    type='search'
                    name='search'
                  />
                </div>
              </div>
            </div>
            {isFilterOpen && (
              <div className='w-[22rem] absolute z-10'>
                <FilterPanel
                  onCancel={() => {
                    setIsFilterOpen(false);
                  }}
                  onApplyFilters={handleApplyFilter}
                  handleResetFilters={() => {
                    setSelectedMerchants([]);
                    setExpectedUnits({});
                  }}
                  classNames={{ panel: 'p-4 ', resetButton: 'text-red-600 mr-3' }}
                >
                  <div className='border-b mb-2'>
                    <CheckboxFilter
                      onChange={(merchants: any) => {
                        setSelectedMerchants(merchants);
                      }}
                      options={merchants.map((merchant) => {
                        return {
                          value: merchant.merchantId,
                          label: merchant.name,
                        };
                      })}
                      selectedOptions={selectedMerchants}
                      label='Merchant Name'
                    />
                  </div>
                  <div className='border-b mb-2 '>
                    <RangeSliderFilter
                      label='No. of Units Expected'
                      onChange={setExpectedUnits}
                      minValue={expectedUnit.min}
                      maxValue={expectedUnit.max}
                      maxLimit={1000}
                    />
                  </div>
                </FilterPanel>
              </div>
            )}
            <div className='mt-2'>
              <DynamicTable
                columns={columns}
                data={orders}
                headerClassname='!text-center'
                rowsClassname='text-center'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
