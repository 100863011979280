import React, { useContext, useEffect, useState } from 'react';
import {
  ArrowPathRoundedSquareIcon,
  ArrowUturnLeftIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  FunnelIcon,
  LinkIcon,
  LockClosedIcon,
  LockOpenIcon,
  MapIcon,
  // XMarkIcon,
  // ArrowRightIcon,
} from '@heroicons/react/24/outline';
import { SignOutButton, useUser } from '@clerk/clerk-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BaseContext from './BaseContext';
import { Menu } from '@headlessui/react';
import { IgniteIcon, roleBasedAccess, roles } from './utils';
import { BackspaceIcon } from '@heroicons/react/24/solid';
import useRequest from '../../hooks/useRequest';
import { apiPaths } from '../../utils/ApiPaths';
import { Organization } from './types';
import { ArrowsUpDownIcon } from '@heroicons/react/20/solid';

interface SubMenu {
  name: string;
  href: string;
  current: boolean;
  icon: JSX.Element;
}

interface NavigationItem {
  name: string;
  href: string;
  icon: any;
  count?: number;
  current?: boolean;
  visible?: boolean;
  subMenus?: SubMenu[];
}

interface SidebarProps {
  onSidebarOpenStateChange: (openState: boolean) => void;
}
const Sidebar: React.FC<SidebarProps> = ({ onSidebarOpenStateChange }: SidebarProps) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [isSidebarLocked, setIsSidebarLocked] = useState(() => {
    const storedValue = localStorage.getItem('isSidebarLocked');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  });

  const [isOpen, setIsOpen] = useState(isSidebarLocked);
  const [isShowOrgSelector, setIsShowOrgSelector] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const isStepsCompleted = +(localStorage.getItem('stepsCompleted') || 0);

  //Api requests
  const { executeRequest: fetchOrganization, data: organizations } = useRequest<Organization[]>(
    apiPaths.GET_ORGANIZATIONS,
  );

  const handleSidebarOpenStateChange = (openState: boolean) => {
    setIsOpen(openState);
    onSidebarOpenStateChange(openState);
  };

  const handleMouseEnter = () => {
    if (!isOpen) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isOpen) {
      setIsHovered(false);
    }
  };

  const toggleSidebar = () => {
    handleSidebarOpenStateChange(!isOpen);
    setIsHovered(false);
  };

  useEffect(() => {
    setIsSidebarLocked(true);
  }, [isOpen]);

  const DashboardIcon = ({ className }: { className?: string }) => (
    <svg
      width='20'
      className={className}
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.54 0H5.92C7.33 0 8.46 1.15 8.46 2.561V5.97C8.46 7.39 7.33 8.53 5.92 8.53H2.54C1.14 8.53 0 7.39 0 5.97V2.561C0 1.15 1.14 0 2.54 0ZM2.54 11.4697H5.92C7.33 11.4697 8.46 12.6107 8.46 14.0307V17.4397C8.46 18.8497 7.33 19.9997 5.92 19.9997H2.54C1.14 19.9997 0 18.8497 0 17.4397V14.0307C0 12.6107 1.14 11.4697 2.54 11.4697ZM17.4601 0H14.0801C12.6701 0 11.5401 1.15 11.5401 2.561V5.97C11.5401 7.39 12.6701 8.53 14.0801 8.53H17.4601C18.8601 8.53 20.0001 7.39 20.0001 5.97V2.561C20.0001 1.15 18.8601 0 17.4601 0ZM14.0801 11.4697H17.4601C18.8601 11.4697 20.0001 12.6107 20.0001 14.0307V17.4397C20.0001 18.8497 18.8601 19.9997 17.4601 19.9997H14.0801C12.6701 19.9997 11.5401 18.8497 11.5401 17.4397V14.0307C11.5401 12.6107 12.6701 11.4697 14.0801 11.4697Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const OrganizationIcon = ({ className }: { className?: string }) => (
    <svg
      width='24'
      height='24'
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.594 2.55655C12.2251 2.34573 11.7722 2.34573 11.4033 2.55655L3.00328 7.35655C2.42786 7.68536 2.22794 8.41838 2.55675 8.9938C2.77801 9.38101 3.1823 9.59819 3.59844 9.59864V17.9984C2.9357 17.9984 2.39844 18.5357 2.39844 19.1984C2.39844 19.8612 2.9357 20.3984 3.59844 20.3984H20.3984C21.0612 20.3984 21.5984 19.8612 21.5984 19.1984C21.5984 18.5357 21.0612 17.9984 20.3984 17.9984V9.59864C20.8147 9.59833 21.2192 9.38114 21.4405 8.9938C21.7693 8.41838 21.5694 7.68536 20.994 7.35655L12.594 2.55655ZM7.19844 10.7984C6.5357 10.7984 5.99844 11.3357 5.99844 11.9984V15.5984C5.99844 16.2612 6.5357 16.7984 7.19844 16.7984C7.86118 16.7984 8.39844 16.2612 8.39844 15.5984V11.9984C8.39844 11.3357 7.86118 10.7984 7.19844 10.7984ZM10.7984 11.9984C10.7984 11.3357 11.3357 10.7984 11.9984 10.7984C12.6612 10.7984 13.1984 11.3357 13.1984 11.9984V15.5984C13.1984 16.2612 12.6612 16.7984 11.9984 16.7984C11.3357 16.7984 10.7984 16.2612 10.7984 15.5984V11.9984ZM16.7984 10.7984C16.1357 10.7984 15.5984 11.3357 15.5984 11.9984V15.5984C15.5984 16.2612 16.1357 16.7984 16.7984 16.7984C17.4612 16.7984 17.9984 16.2612 17.9984 15.5984V11.9984C17.9984 11.3357 17.4612 10.7984 16.7984 10.7984Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const InboundIcon = ({ className }: { className?: string }) => (
    <svg
      width='24'
      height='24'
      className={className}
      viewBox='0 0 38 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.582 12H6.41536M6.41536 12L10.2348 17M6.41536 12L10.2348 7'
        stroke='#9A9AA9'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.5789 4.43152L29.5789 3.38197C27.8233 2.46066 26.9455 2 26.0012 2C25.0569 2 24.1791 2.46066 22.4235 3.38197L20.4235 4.43152C18.6498 5.36234 17.6071 5.9095 16.9609 6.64132L26.0012 11.1615L35.0415 6.64132C34.3953 5.9095 33.3527 5.36234 31.5789 4.43152Z'
        fill='#9A9AA9'
      />
      <path
        d='M35.7484 7.96387L26.75 12.463V21.9035C27.4679 21.7247 28.2848 21.296 29.5777 20.6175L31.5777 19.568C33.7294 18.4388 34.8052 17.8743 35.4026 16.8598C36 15.8453 36 14.5828 36 12.058V11.941C36 10.0484 36 8.8651 35.7484 7.96387Z'
        fill='#9A9AA9'
      />
      <path
        d='M25.25 21.9035V12.463L16.2516 7.96387C16 8.8651 16 10.0484 16 11.941V12.058C16 14.5828 16 15.8453 16.5974 16.8598C17.1948 17.8743 18.2706 18.4388 20.4223 19.568L22.4223 20.6175C23.7152 21.296 24.5321 21.7247 25.25 21.9035Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const OutboundIcon = ({ className }: { className?: string }) => (
    <svg
      width='24'
      height='24'
      className={className}
      viewBox='0 0 37 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.418 12H30.5846M30.5846 12L26.7652 7M30.5846 12L26.7652 17'
        stroke='#9A9AA9'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5789 4.43152L15.5789 3.38197C13.8233 2.46066 12.9455 2 12.0012 2C11.0569 2 10.1791 2.46066 8.42354 3.38197L6.42354 4.43152C4.6498 5.36234 3.60715 5.9095 2.96094 6.64132L12.0012 11.1615L21.0415 6.64132C20.3953 5.9095 19.3527 5.36234 17.5789 4.43152Z'
        fill='#9A9AA9'
      />
      <path
        d='M21.7484 7.96387L12.75 12.463V21.9035C13.4679 21.7247 14.2848 21.296 15.5777 20.6175L17.5777 19.568C19.7294 18.4388 20.8052 17.8743 21.4026 16.8598C22 15.8453 22 14.5828 22 12.058V11.941C22 10.0484 22 8.8651 21.7484 7.96387Z'
        fill='#9A9AA9'
      />
      <path
        d='M11.25 21.9035V12.463L2.25164 7.96387C2 8.8651 2 10.0484 2 11.941V12.058C2 14.5828 2 15.8453 2.5974 16.8598C3.19479 17.8743 4.27063 18.4388 6.42229 19.568L8.42229 20.6175C9.71524 21.296 10.5321 21.7247 11.25 21.9035Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const ProductsIcon = ({ className }: { className?: string }) => (
    <svg
      width='26'
      height='24'
      className={className}
      viewBox='0 0 26 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9984 2.40039C10.1266 2.40039 7.79844 4.54942 7.79844 7.20039V8.40039H6.49844C5.83603 8.40039 5.27955 8.86015 5.20639 9.46787L3.90639 20.2679C3.86556 20.6071 3.98317 20.9464 4.22978 21.2007C4.47639 21.455 4.82867 21.6004 5.19844 21.6004H20.7984C21.1682 21.6004 21.5205 21.455 21.7671 21.2007C22.0137 20.9464 22.1313 20.6071 22.0905 20.2679L20.7905 9.46787C20.7173 8.86015 20.1609 8.40039 19.4984 8.40039H18.1984V7.20039C18.1984 4.54942 15.8703 2.40039 12.9984 2.40039ZM15.5984 8.40039V7.20039C15.5984 5.87491 14.4344 4.80039 12.9984 4.80039C11.5625 4.80039 10.3984 5.87491 10.3984 7.20039V8.40039H15.5984ZM7.79844 12.0004C7.79844 11.3376 8.38047 10.8004 9.09844 10.8004C9.81641 10.8004 10.3984 11.3376 10.3984 12.0004C10.3984 12.6631 9.81641 13.2004 9.09844 13.2004C8.38047 13.2004 7.79844 12.6631 7.79844 12.0004ZM16.8984 10.8004C16.1805 10.8004 15.5984 11.3376 15.5984 12.0004C15.5984 12.6631 16.1805 13.2004 16.8984 13.2004C17.6164 13.2004 18.1984 12.6631 18.1984 12.0004C18.1984 11.3376 17.6164 10.8004 16.8984 10.8004Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const InventoryIcon = ({ className }: { className?: string }) => (
    <svg
      width='24'
      height='24'
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_572_11063)'>
        <mask id='mask0_572_11063' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
          <path d='M24 0H0V24H24V0Z' fill='white' />
        </mask>
        <g mask='url(#mask0_572_11063)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1 0C1.55228 0 2 0.447715 2 1V8H5C4.44772 8 4 7.55228 4 7V5C4 4.44772 4.44772 4 5 4H9C9.55228 4 10 4.44772 10 5V7C10 7.55228 9.55228 8 9 8H13C12.4477 8 12 7.55228 12 7V3C12 2.44772 12.4477 2 13 2H19C19.5523 2 20 2.44772 20 3V7C20 7.55228 19.5523 8 19 8H22V1C22 0.447715 22.4477 0 23 0C23.5523 0 24 0.447715 24 1V23C24 23.5523 23.5523 24 23 24C22.4477 24 22 23.5523 22 23V22H2V23C2 23.5523 1.55228 24 1 24C0.447715 24 0 23.5523 0 23V1C0 0.447715 0.447715 0 1 0ZM22 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14H14C13.4477 14 13 14.4477 13 15V19C13 19.5523 13.4477 20 14 20H10C10.5523 20 11 19.5523 11 19V13C11 12.4477 10.5523 12 10 12H5C4.44772 12 4 12.4477 4 13V19C4 19.5523 4.44772 20 5 20H2V10H22V20Z'
            fill='#9A9AA9'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_572_11063'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );

  const ClientsIcon = ({ className }: { className?: string }) => (
    <svg
      width='24'
      height='24'
      className={className}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5992 7.19961C15.5992 9.18783 13.9874 10.7996 11.9992 10.7996C10.011 10.7996 8.39922 9.18783 8.39922 7.19961C8.39922 5.21138 10.011 3.59961 11.9992 3.59961C13.9874 3.59961 15.5992 5.21138 15.5992 7.19961Z'
        fill='#9A9AA9'
      />
      <path
        d='M21.5992 9.59961C21.5992 10.9251 20.5247 11.9996 19.1992 11.9996C17.8737 11.9996 16.7992 10.9251 16.7992 9.59961C16.7992 8.27413 17.8737 7.19961 19.1992 7.19961C20.5247 7.19961 21.5992 8.27413 21.5992 9.59961Z'
        fill='#9A9AA9'
      />
      <path
        d='M16.7992 17.9996C16.7992 15.3486 14.6502 13.1996 11.9992 13.1996C9.34825 13.1996 7.19922 15.3486 7.19922 17.9996V21.5996H16.7992V17.9996Z'
        fill='#9A9AA9'
      />
      <path
        d='M7.19922 9.59961C7.19922 10.9251 6.1247 11.9996 4.79922 11.9996C3.47374 11.9996 2.39922 10.9251 2.39922 9.59961C2.39922 8.27413 3.47374 7.19961 4.79922 7.19961C6.1247 7.19961 7.19922 8.27413 7.19922 9.59961Z'
        fill='#9A9AA9'
      />
      <path
        d='M19.1992 21.5996V17.9996C19.1992 16.7346 18.873 15.5459 18.3001 14.5128C18.5875 14.4389 18.8888 14.3996 19.1992 14.3996C21.1874 14.3996 22.7992 16.0114 22.7992 17.9996V21.5996H19.1992Z'
        fill='#9A9AA9'
      />
      <path
        d='M5.69833 14.5128C5.12544 15.5459 4.79922 16.7346 4.79922 17.9996V21.5996H1.19922V17.9996C1.19922 16.0114 2.81099 14.3996 4.79922 14.3996C5.10967 14.3996 5.41094 14.4389 5.69833 14.5128Z'
        fill='#9A9AA9'
      />
    </svg>
  );

  const { organization, orgRole, setOrganization } = useContext(BaseContext);
  const orgId: string = organization?.organizationId;

  const location = useLocation();
  const navigation: NavigationItem[] = [
    {
      name: 'Get Started',
      href: `/${orgId}`,
      icon: MapIcon,
      count: null,
      current: location.pathname === `/${orgId}`,
      visible: isStepsCompleted === 0,
    },
    {
      name: 'Dashboard',
      href: `/${orgId}/dashboard`,
      icon: DashboardIcon,
      current: location.pathname === `/${orgId}/dashboard`,
      visible: true,
    },
    {
      name: 'Inbound Orders',
      href: `/${orgId}/inbound`,
      icon: InboundIcon,
      current: location.pathname.includes(`/${orgId}/inbound`),
      visible: true,
    },
    {
      name: 'Outbound',
      href: `/${orgId}/outbound`,
      icon: OutboundIcon,
      visible: true,
      subMenus: [
        {
          name: 'Orders',
          href: `/${orgId}/outbound`,
          current: location.pathname.includes(`/${orgId}/outbound`),
          icon: <ArrowPathRoundedSquareIcon className='w-4 h-4 my-auto' />,
        },
        {
          name: 'Fulfillment',
          href: `/${orgId}/fulfillment`,
          current: location.pathname.includes(`/${orgId}/fulfillment`),
          icon: <FunnelIcon className='w-4 h-4 my-auto' />,
        },
      ],
    },
    {
      name: 'Products',
      href: `/${orgId}/products`,
      icon: ProductsIcon,
      current: location.pathname === `/${orgId}/products`,
      visible: true,
    },
    {
      name: 'Inventory',
      href: `/${orgId}/inventory`,
      icon: InventoryIcon,
      current: location.pathname === `/${orgId}/inventory`,
      visible: true,
    },
    {
      name: 'Clients',
      href: `/${orgId}/clients`,
      icon: ClientsIcon,
      current: location.pathname === `/${orgId}/clients`,
      visible: roleBasedAccess['screen.merchants'].includes(orgRole as roles),
    },
    {
      name: 'Connections',
      href: `/${orgId}/connections`,
      icon: LinkIcon,
      current: location.pathname === `/${orgId}/connections`,
      visible: true,
    },
    {
      name: 'Organization',
      href: `/${orgId}/organizations`,
      icon: OrganizationIcon,
      current: location.pathname === `/${orgId}/organizations`,
      visible: roleBasedAccess['screen.organizations'].includes(orgRole as roles),
    },
  ];

  const getInitials = (name: string) => {
    return name
      ? name
          .split(' ')
          .map((str) => str[0])
          .join('')
      : '';
  };

  const renderNavItem = (item: NavigationItem) => (
    <li key={item.name}>
      <Link
        to={item.href}
        className={`group flex items-center gap-3 rounded-md p-2 text-sm font-semibold ${
          item.current
            ? 'bg-hopstack-blue-50 text-hopstack-blue-700'
            : 'text-gray-400 hover:text-hopstack-blue-800'
        }`}
      >
        <item.icon
          className={`h-6 w-6 shrink-0 ${
            item.current ? 'text-hopstack-blue-700' : 'text-gray-400'
          }`}
        />

        <span className={` whitespace-nowrap ${isOpen || isHovered ? 'ml-2' : 'sr-only'}`}>
          {item.name}
        </span>

        {item.count && (isOpen || isHovered) && (
          <span className='ml-auto rounded-full bg-hopstack-blue-100 px-2 py-0.5 text-xs font-medium text-hopstack-blue-600'>
            {item.count}
          </span>
        )}
        <div> {item.visible} </div>
      </Link>
      {(isOpen || isHovered) &&
        item.subMenus?.map((subItem) => (
          <Link
            key={subItem.name}
            to={subItem.href}
            className={`group ml-6 flex items-center gap-1 rounded-md p-1 text-sm font-semibold ${
              subItem.current
                ? 'bg-hopstack-blue-50 text-hopstack-blue-600'
                : 'text-gray-400 hover:text-black'
            }`}
          >
            <span className='shrink-0'>{subItem.icon}</span>

            <span>{subItem.name}</span>
          </Link>
        ))}
    </li>
  );

  const handleMenuButtonClick = async () => {
    if (organizations && organizations.length > 0) {
      return;
    }
    const organizationsResponse = await fetchOrganization({
      queryParams: {},
      urlParams: {},
    });

    if (organizationsResponse.success) {
      setIsShowOrgSelector(true);
    }
  };

  useEffect(() => {
    handleMenuButtonClick();
  }, []);

  const handleSwitchOrgs = () => {
    //Resetting the org in baseContext
    localStorage.setItem('isSidebarLocked', JSON.stringify(isSidebarLocked));
    setOrganization(null);
    navigate('/', {});
  };

  return (
    <>
      {/* Background overlay */}
      <div
        className={`fixed inset-0 z-20  ${
          isHovered ? 'bg-gray-500 bg-opacity-50 ' : 'pointer-events-none opacity-0'
        } ${isOpen ? 'opacity-100' : ''}`}
      />
      {/* Sidebar */}
      <div
        className={`left-0 z-30 top-0 bottom-0 flex flex-col gap-5 overflow-y-auto h-screen bg-white p-6 shadow transition-all duration-500 ${
          isOpen || isHovered ? 'w-[16rem]' : 'w-20'
        } ${isOpen ? 'relative' : 'absolute'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Ignite Icon */}
        <div className='flex h-16 items-center shrink-0'>
          <IgniteIcon className={`h-12 w-12 shrink-0 -ml-2`} />
        </div>
        {/* Navigation */}
        <nav className='flex flex-1 flex-col'>
          <ul role='list' className='flex flex-1 flex-col gap-3 justify-between'>
            <li>
              <ul role='list' className='-mx-2 space-y-1'>
                {orgId && navigation.map((item) => item.visible && renderNavItem(item))}
              </ul>
            </li>
            {
              <li className='-mx-6 -mb-5'>
                <Menu as='div' className='relative'>
                  <Menu.Button className='flex w-full items-start justify-between py-2 text-sm font-semibold text-black hover:bg-gray-100 hover:shadow-md'>
                    <div className='flex items-center justify-evenly gap-2'>
                      <div className=''>
                        <BackspaceIcon className='absolute left-0 h-18 w-20 -mt-9 -translate-x-[4.1rem] overflow-clip pr-[2px] text-hopstack-blue-700' />
                      </div>
                      <div className='flex h-10 w-10 items-center justify-center rounded-full bg-white py-2 ml-2 font-bold shadow'>
                        {getInitials(
                          user?.fullName || user?.primaryEmailAddress?.emailAddress || '',
                        )}
                      </div>
                      {(isOpen || isHovered) && (
                        <div>
                          <div className='flex items-center'>
                            <div className='text-left text-[13px] font-medium text-ellipsis whitespace-nowrap overflow-hidden max-w-[100px]'>
                              {user?.fullName || user?.primaryEmailAddress?.emailAddress}
                            </div>
                            <div className='rounded-lg bg-hopstack-blue-100 px-1.5 text-[9px] font-semibold capitalize text-blue-600 scale-75'>
                              {orgRole?.trim()}
                            </div>
                          </div>
                          <div className='truncate text-left text-[12px] text-gray-500'>
                            {organization?.name}
                          </div>
                        </div>
                      )}
                      {(isOpen || isHovered) && <ChevronRightIcon className='h-4 w-4 text-black' />}
                    </div>
                  </Menu.Button>
                  {(isOpen || isHovered) && (
                    <Menu.Items
                      className='!z-[100] ml-4 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'
                      anchor='top start'
                    >
                      <div>
                        <Menu.Item as='button' className='w-full'>
                          {({ active }) => (
                            <Link
                              target='blank'
                              to={'https://help.ignitefulfill.com/home/'}
                              className={`flex w-full gap-2 p-2 text-[12px] ${
                                active ? 'bg-gray-100' : ''
                              }`}
                            >
                              <DocumentTextIcon className='h-4 w-4 my-auto' />
                              <span>Docs</span>
                            </Link>
                          )}
                        </Menu.Item>
                        {isShowOrgSelector && (
                          <Menu.Item as='button' className='w-full'>
                            {({ active }) => (
                              <div
                                className={`flex w-full gap-2 p-2 text-[12px] cursor-pointer ${
                                  active ? 'bg-gray-100' : ''
                                }`}
                                onClick={handleSwitchOrgs}
                              >
                                <ArrowsUpDownIcon className='h-4 w-4 my-auto' />
                                Switch Orgs
                              </div>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item as='button' className='w-full'>
                          {({ active }) => (
                            <SignOutButton>
                              <button
                                className={`flex w-full gap-2 p-2 text-[12px] ${
                                  active ? 'bg-gray-100' : ''
                                }`}
                              >
                                <ArrowUturnLeftIcon className='h-4 w-4 my-auto' />
                                <span>Sign Out</span>
                              </button>
                            </SignOutButton>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  )}
                </Menu>
              </li>
            }
          </ul>
        </nav>

        <div className='absolute right-4 top-10 text-gray-600 hover:text-gray-800'>
          {!isOpen && isHovered && (
            <button onClick={toggleSidebar}>
              <LockOpenIcon className='h-5 w-5' />
            </button>
          )}
          {isOpen && (
            <button onClick={toggleSidebar}>
              <LockClosedIcon className='h-5 w-5' />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
