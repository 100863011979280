import React, { useState } from 'react';
import ReactDOM from 'react-dom';

interface TooltipProps {
  title: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ title, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const handleMouseEnter = (event: React.MouseEvent) => {
    setVisible(true);
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    setTooltipPosition({ top: rect.top - 10, left: rect.left + rect.width / 2 }); // Adjust the position
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div
      className='relative inline-block'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {visible && ReactDOM.createPortal(
        <div
          style={{
            position: 'absolute',
            top: tooltipPosition.top,
            left: tooltipPosition.left,
            transform: 'translate(-50%, -100%)',
            zIndex: 9999, 
          }}
          className='px-2 py-1 bg-white text-black text-sm font-semibold rounded shadow-lg'
        >
          {title}
        </div>,
        document.body // Render the tooltip in the body
      )}
    </div>
  );
};

export default Tooltip;
